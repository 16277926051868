import React from 'react'
import { graphql } from 'gatsby'
import NavigationLanding from '../components/navigation-landing'
import posed from 'react-pose'

const Section = posed.section({
  enter: { opacity: 1 },
  exit: { opacity: 0, delay: -1, beforeChildren: false }
})

const IndexPage = (props) => {
	 const containerStyle = {
      backgroundImage: `url(${props.data.allContentfulAsset.edges[0].node.fluid.src})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      right: '0',
      left: '0',
      height: '100vh',
      width: '100vw'
    }
    return (
        <Section>
          <div className="generic-container home-container" style={containerStyle}>
            <div className="landing-page-outer">
  	        	<div className="landing-page-container">
                    <h2 id="personal-title" className="padding-five">luis m. castañeda</h2>
  	            <NavigationLanding></NavigationLanding>
  	          </div>
            </div>
          </div>
        </Section>
    )
};

export default IndexPage

export const indexPageQuery = graphql`
	query {
		   allContentfulAsset(
            filter: {
                contentful_id: {eq: "6TxIlbJDjOouKeGYGse0OM"}
            }

        ) {
            edges {
                node {
                    id
                    fluid (quality: 100) {
                      src
                    }
                }
            }
    }
	}
`

// import React from 'react'
// import { Link } from 'gatsby'

// import Layout from '../components/layout'
// import Image from '../components/image'

// const IndexPage = () => (
//   <Layout>
//     <h1>Hi people</h1>
//     <p>Welcome to your new Gatsby site.</p>
//     <p>Now go build something great.</p>
//     <div style={{ maxWidth: '300px', marginBottom: '1.45rem' }}>
//       <Image />
//     </div>
//     <Link to="/page-2/">Go to page 2</Link>
//   </Layout>
// )

// export default IndexPage
