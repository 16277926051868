import React from 'react'
import Link from 'gatsby-link'

const NavigationLanding = () => {
	return  (
		<div>
			<p className="core-links"><Link to="/about">about</Link></p>
			<p className="core-links"><Link to="/writing/page/1">writing</Link></p>
			<p className="core-links"><Link to="/press/page/1">press</Link></p>
		</div>
	)
}

export default NavigationLanding
